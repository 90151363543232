
import { defineComponent, ref, reactive } from 'vue'
import httpRequest from '@/service'
import { useRoute, useRouter } from 'vue-router'
import BreadCrumb from '@/components/breadCrumb/index.vue'
import basicInfos from './cpns/basicInfos.vue'
import analyseCase from './cpns/analyseCase.vue'
import wdocument from './cpns/document.vue'
import recommend from './cpns/recommend.vue'
import { isCollect } from '@/utils/collect'
import { debounce } from 'throttle-debounce'
import PopoverMenu from '@/components/PopoverMenu/index.vue'
import usePopoverMenu from '@/utils/usePopoverMenu'
import { generateTagStyle } from '@/utils/css'
interface IObj {
  id: string
  [key: string]: any
}
interface IList {
  title: string
  data: IObj[]
  basicInfos: any
  getData: () => void
}
export default defineComponent({
  name: 'DetailCasePage',
  components: {
    BreadCrumb,
    basicInfos,
    analyseCase,
    wdocument,
    recommend,
    PopoverMenu
  },
  setup() {
    const { popShow, popLeft, popTop, showPop,handleCopy,handleSearch } = usePopoverMenu((value)=>{
      const routeData = router.resolve({ path: '/SearchCaseList', query: { name:'案件名称',field:'searchCase',value } })
      window.open(routeData.href, '_blank')
    })
    const loading = ref(true)
    const route = useRoute()
    const router = useRouter()

    // 字体大小切换
    const fontSizeRef = ref()
    const fontSizeState = reactive({
      curSize: 16, // 16-24
      sizeChange: (type: string) => {
        if (type === 'smaller' && fontSizeState.curSize < 18) {
          alert('已经缩小到最小')
          return
        }
        if (type === 'bigger' && fontSizeState.curSize > 22) {
          alert('已经放大到最大')
          return
        }
        fontSizeState.curSize =
          type === 'smaller'
            ? fontSizeState.curSize - 2
            : fontSizeState.curSize + 2
        fontSizeRef.value.style.fontSize = String(fontSizeState.curSize + 'px')
      }
    })

    // 背景颜色切换
    const skinState = reactive({
      data: ['back1', 'back2', 'back3'],
      data2: ['card1', 'card2', 'card3'],
      curSkin: 'back1',
      curSkin2: 'card1',
      change: (index: number) => {
        skinState.curSkin = skinState.data[index]
        skinState.curSkin2 = skinState.data2[index]
      }
    })

    // 下载
    const dowmloadLink = ref('')
    // 收藏
    const isFavor = debounce(500, () => {
      isFavorValue.value = !isFavorValue.value
      isCollect(isFavorValue.value, currentID.value, 2)
    })

    const { startTag, endTag } = generateTagStyle({ tag: "p", style: { textIndent: "2em" }})
    const isFavorValue = ref(false)
    const currentID = ref('')
    const currentName = ref('')
    const detailCasePageList = reactive<IList>({
      title: '',
      data: [],
      basicInfos: [],
      getData() {
        httpRequest
          .post({
            url: 'detailCasePageList',
            params: { id: route.query.id }
          })
          .then((res) => {
            console.log(res.data)

            // 首行缩进处理
            // 01 处理 basicInfos 中的内容
            const basicInfosIndexs = [5, 14, 15, 16]
            basicInfosIndexs.forEach((key, index) => {
              if(res.data[key]) return;
              if(index === 0) res.data.analyse = res.data.analyse.split(/\r?\n/).map((item: string) => startTag + item + endTag).join("")
              res.data.basicInfos[key].value = res.data.basicInfos[key].value.split(/\r?\n/).map((item: string) => startTag + item + endTag).join("")
            })

            detailCasePageList.data = res.data
            detailCasePageList.basicInfos = res.data.basicInfos
            detailCasePageList.title = res.data.name
            if(detailCasePageList.title){
              const title = document.getElementsByTagName('title')[0]
              title.innerHTML = detailCasePageList.title
            }
            dowmloadLink.value = res.data.download
            currentID.value = res.data.id
            currentName.value = res.data.name
            loading.value = false
            // 判断是否收藏
            httpRequest.post({
              url: 'judgeCollection2',
              params: { caseId: route.query.id }
            }).then(res2=>{
              isFavorValue.value = res2.data
            })
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
    detailCasePageList.getData()

    return {
      loading,
      detailCasePageList,
      dowmloadLink,
      isFavor,
      isFavorValue,
      fontSizeRef,
      fontSizeState,
      skinState,
      currentID,
      currentName,
      popShow,
      popLeft,
      popTop,
      showPop,
      handleCopy,
      handleSearch,
      REQUIRE_LOGIN:process.env.VUE_APP_REQUIRE_LOGIN
    }
  }
})
