type tagStyleOptionsType = {
  tag: string
  class?: string[]
  style?: {
    [key: string]: string
  }
}

// 将 camelCase 风格的字符串转换为 kebab-case 风格的字符串
export const camelToKebab = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}

// 根据配置生成标签和样式
export const generateTagStyle = (tagStyleOptions: tagStyleOptionsType) => {
  const { tag, class: className, style: styles } = tagStyleOptions

  const classNameString = className && className.length ? `class='${className.join(' ')}'` : ''
  const styleString =
    styles && Object.keys(styles).length
      ? `style='${Object.keys(styles)
          .map((key) => `${camelToKebab(key)}:${styles[key]}`)
          .join(';')}'`
      : ''

  const startTag = classNameString || styleString ? `<${tag} ${classNameString} ${styleString}>` : `<${tag}>`
  const endTag = `</${tag}>`

  return { startTag, endTag }
}
